import React from "react";
import Seo from "../../components/Seo";
import ZendeskChat from "../../components/ZendeskChat";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import LayoutDisableHeader from "../../components/LayoutDisableHeader";
import { graphql } from "gatsby";

const PageCanceled = ({ pageContext }) => {
  const { t } = useTranslation();
  pageContext.containerClasses = "mb-5";

  return (
    <LayoutDisableHeader>
      <Seo title={t("Paiement annulé")}>
        <body className="page-checkout-canceled layout-h100" />
      </Seo>

      <ZendeskChat />

      <main className="d-flex flex-column align-items-center justify-content-between">
        <div className="container">
          <div className="bg-white p-4 p-xl-5">
            <h1 className="pt-4 pt-xl-5 text-danger mb-4 mb-md-4 mb-xl-5">
              <i className="bi bi-cart-check-fill"></i> <Trans>Paiement annulé /refusé</Trans>
            </h1>

            <p>
              <Trans>
                Votre paiement n'a pas été confirmé, votre commande a été annulée. Si vous rencontrez des problèmes, <a href={`mailto:${process.env.GATSBY_EMAIL_GENERAL}`}>{"contactez-nous"}</a>.
              </Trans>
            </p>

            <hr />

            <div className="text-left">
              <p className="text-muted">
                <i className="bi bi-arrow-return-left"></i>{" "}
                <Link to="/" title={t("Accueil")} className="text-muted">
                  <Trans>retour</Trans>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </main>
    </LayoutDisableHeader>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    imageBgPage: file(sourceInstanceName: { eq: "img_theme" }, relativePath: { eq: "checkout-canceled.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 50, maxWidth: 3840, srcSetBreakpoints: [200, 400, 640, 860, 1280, 1536, 1920, 2048, 2732, 3840]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default PageCanceled;
